.dashboard {
  @apply h-screen flex overflow-hidden bg-gray-100;

  .mobilesidebarcontainer {
    @apply fixed inset-0 flex z-40;

    .mobileoverlay {
      @apply fixed inset-0;
      .overlaycolor {
        @apply absolute inset-0 bg-gray-600 opacity-75;
      }
    }
    .mobilemenu {
      @apply flex-1 h-0 pt-5 pb-4 overflow-y-auto;
      .homelink {
        @apply flex-shrink-0 flex items-center px-4;
      }
    }
  }

  .mobilesidebar {
    @apply relative flex-1 flex flex-col h-full max-w-xs w-full bg-gray-800;

    .closecontainer {
      @apply absolute top-0 right-0 -mr-12 pt-2;
      .menubuttonclose {
        @apply ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white;
      }
    }
    .navcontainer {
      @apply mt-5 px-2 space-y-1;
    }
    .profilecontainer {
      @apply flex-shrink-0 flex bg-gray-700 p-4;
      .profilelink {
        @apply flex-shrink-0 group block;
      }
    }
  }

  .content {
    @apply flex flex-col w-0 flex-1 overflow-hidden;
    .menubuttoncontainer {
      @apply md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3;
      .menubuttonopen {
        @apply -ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500;
      }
    }
    .innercontent {
      @apply flex-1 relative z-0 overflow-y-auto focus:outline-none;
    }
  }

  .desktopsidebar {
    @apply hidden md:flex md:flex-shrink-0;
    .desktopmenu {
      @apply flex flex-col w-64;
      .inner {
        @apply flex flex-col h-0 flex-1 bg-gray-800;
        .linkscontainer {
          @apply flex-1 flex flex-col pt-5 pb-4 overflow-y-auto;
          .navcontainer {
            @apply mt-5 flex-1 px-2 bg-gray-800 space-y-1;
          }
        }
        .profilecontainer {
          @apply flex-shrink-0 flex bg-gray-700 p-4;
          .profilelink {
            @apply flex-shrink-0 group block;
          }
        }
      }
    }
  }

  .navlink {
    @apply text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 font-medium rounded;
  }

  .navlinkactive {
    @apply bg-gray-900 text-white group flex items-center px-2 py-2 font-medium rounded;
  }

  .dashbutton {
    @apply bg-gray-600 text-white flex items-center justify-center rounded font-medium py-2 px-4;
  }
}

.profilecontainer {
  h2 {
    @apply mt-8;
  }
  .languagecontainer {
    @apply flex flex-row mt-2 mb-8;
    button {
      @apply mr-3;
    }
  }
}
