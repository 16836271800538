.twocolumn {
  @apply grid gap-4 w-full grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2;
}
.threecolumn {
  @apply grid gap-4 w-full grid-cols-3;
}
.admininput {
  @apply border rounded-sm p-2;
}

// Remove this when tiny-mce editor is registered for the production environment
.tox-notifications-container {
  display: none !important;
}
