html {
  @apply text-gray-800 text-base;

  font-family: 'Roboto', sans-serif;
}
h1 {
  @apply text-3xl;
}
h2 {
  @apply text-2xl;
}
h3 {
  @apply text-xl;
}
