@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-lp-black bg-lp-white;
}

h1 {
  @apply font-semibold;
}

h2 {
  @apply font-semibold;
}

h3 {
  @apply font-semibold;
}
