.pagecontainer {
  h2 {
    @apply text-2xl my-8;
  }
  .article {
    @apply pb-4;
    .preview {
      .previewcontainer {
        @apply overflow-scroll;

        ul {
          @apply mb-8;
        }

        ul.articleheader {
          @apply mb-0;
        }

        p {
          @apply mb-4 font-normal sm:max-w-2xl 2xl:text-lg font-sans;
        }

        li {
          @apply mb-4 font-normal sm:max-w-2xl 2xl:text-lg font-sans;

          h3 {
            @apply text-base mb-0;
          }
        }

        a {
          @apply text-lp-blue;
        }

        h5 {
          @apply text-base mt-10 text-lp-dark-brown 2xl:text-xl;
        }

        h5 + p {
          @apply -mt-2;
        }
      }
    }

    .previewextra {
      @apply pt-6 md:pt-0 pb-4 md:pb-0 md:pl-3 md:pr-4 my-8 text-center sm:text-left border-t-4 md:border-t-0 border-b-4 md:border-b-0 md:border-l-4 border-lp-green bg-none;

      h4 {
        @apply text-lp-dark-brown;
      }

      p {
        @apply text-sm leading-snug;

        br {
          @apply mb-4;
        }
      }

      p:last-of-type {
        @apply mb-0;
      }
    }
  }
  .images {
    @apply pb-4;
    .imagegrid {
      @apply grid gap-4 grid-cols-1 md:grid-cols-1 lg:grid-cols-3;
      .imagecontainer {
        @apply h-32 p-1 rounded-sm;
        img {
          @apply h-full object-contain;
        }
      }

      .selectedimage {
        border: 2px solid red;
      }

      .mainimage {
        @apply h-96 w-full col-start-1 col-end-4;
      }

      .hoverimage {
        @apply border-dashed border-red-500 border-2;
      }
    }
  }
  .fileuploadbox {
    @apply max-w-xl flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md transition;
  }
  .fileuploadactive {
    @apply bg-gray-300 border-gray-400;
  }
  .characteristicstable {
    @apply px-2;
    tr {
      @apply py-4;
    }
    tbody {
      tr:nth-child(odd) {
        @apply bg-gray-200;
      }
    }

    th {
      @apply px-4 text-left border-b;
    }
    td {
      @apply px-4 py-2;
    }
  }
  .controls {
    @apply flex flex-col mb-8 space-y-4;
    .charaddheader {
      @apply px-2;
    }
    .characteradd {
      @apply flex flex-row items-center;
      .charaddselect {
        @apply w-96;
      }
      button {
        @apply ml-4;
      }
    }
    .tablesave {
      .savebutton {
        @apply ml-4;
      }
    }
  }
  > .names > .rank {
    @apply text-base text-gray-500 italic;
  }
}

.tablinks {
  @apply flex flex-row items-center justify-start flex-wrap mb-4;
}

.lp-button {
  @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition;

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  &.-default {
    @apply border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900;
  }

  &.-danger {
    @apply bg-red-600 hover:bg-red-700 focus:ring-red-500;
  }
}

.lp-button-link {
  @apply px-4 py-2 whitespace-nowrap text-right text-sm font-medium text-indigo-600 hover:text-indigo-900;
}

.lp-input-text {
  @apply block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md placeholder-gray-500 placeholder-opacity-50;

  &[readonly] {
    @apply opacity-50 cursor-not-allowed focus:ring-0 focus:border-gray-300;
  }
}

.lp-input-checkbox {
  @apply focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded;

  &[readonly] {
    @apply opacity-50 cursor-not-allowed focus:ring-0 focus:border-gray-300;
  }
}

.lp-input-radio {
  @apply focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300;
}

.lp-input-text-label {
  @apply block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2;
}

.lp-textarea {
  @apply shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}

.lp-table-container {
  @apply flex shadow overflow-hidden border-b border-gray-200 rounded-lg;
}

.lp-table {
  @apply divide-y divide-gray-200;

  thead {
    tr {
      @apply bg-gray-50;
    }
  }

  tbody {
    tr {
      @apply bg-white;
    }
    tr:nth-child(odd) {
      @apply bg-gray-100;
    }
  }

  th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }

  td {
    @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
  }
}

.lp-page {
  @apply flex flex-col items-start;
}

.previewcell {
  @apply max-w-md overflow-ellipsis whitespace-nowrap overflow-hidden;
}

.lp-taxonomy-tree {
  @apply flex flex-col;

  > .rows {
    @apply flex flex-col;

    > .row {
      @apply flex flex-row items-center relative;
    }

    > .row > .left {
      @apply flex bg-lp-lightgrey mr-2;

      min-height: 2px;
    }

    > .row > .right {
      @apply flex flex-col py-2;
    }

    > .row > .right > .ranktitle {
      @apply text-sm font-normal mb-0;
    }

    > .row > .right > .link {
      @apply text-sm text-lp-green;
    }

    > .row::after {
      @apply bg-lp-lightgrey absolute left-0;

      content: '';
      height: 100%;
      width: 2px;
    }

    > .row.-first::after {
      @apply bottom-0;

      height: 50%;
    }

    > .row.-last::after {
      @apply top-0;

      height: 50%;
    }

    > .row.-first.-last {
      &::after {
        height: 0;
      }

      > .left {
        @apply bg-transparent;
      }
    }

    > .row.-selected > .right > .ranktitle {
      @apply font-bold;
    }
  }
}

.lp-description-panel {
  @apply bg-white shadow overflow-hidden sm:rounded-lg;

  > .header {
    @apply px-4 py-5 sm:px-6;
  }

  > .header > .title {
    @apply text-lg leading-6 font-medium text-gray-900;
  }

  > .body {
    @apply border-t border-gray-200 px-4 py-5 sm:p-0;
  }

  > .body > .list {
    @apply sm:divide-y sm:divide-gray-200;
  }

  > .body > .list > .item {
    @apply py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6;
  }

  > .body > .list > .item > .title {
    @apply text-sm font-medium text-gray-500;
  }

  > .body > .list > .item > .value {
    @apply mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2;
  }
}
